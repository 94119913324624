
//import JnPubblicazioniSearch from "@/views/search/PubblicazioniSearch.vue";
import JnCardsStrip from "@/views/common/CardsStrip.vue";
import JnSearchResults from "@/views/search/SearchResults.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { SearchData } from "@/models/search.model";
import { Testata } from '@/models/testata.model';
import MetaInfo from "vue-meta";
import {
  Sections,
  RoutesToCategories,
  RoutesToNames,
  Video,
} from "@/models/enums.model";
import { EsService } from "@/services/es.service";
import JnMainNewsGroup from "@/views/common/MainNewsGroup.vue";
import EventBus from "@/services/event.bus";
import { Pagination } from "@/models/pagination.model";
import { Document } from "@/models/document.model";
import JnDoc from "@/views/common/DocCard.vue";

@Component({
  components: {
    //JnPubblicazioniSearch,
    JnCardsStrip,
    JnSearchResults,
    JnMainNewsGroup,
    JnDoc
  },
  metaInfo(this: JnVideo): MetaInfo {
    return {
      title: "JuraNews - Video & Podcast",
    };
  },
})
export default class JnVideo extends Vue {

  testata?: Testata;

  searchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    keywords: [],
    perPage: 20,
    from: 0,
    searchScope: "video",
  });

  pilloleVideoPagination?: Pagination;
  pilloleVideoAllDocs: Document[] = [];
  pilloleVideoShownDocs: Document[] = [];

  approfondimentiPagination?: Pagination;
  approfondimentiAllDocs: Document[] = [];
  approfondimentiShownDocs: Document[] = [];

  podcastPagination?: Pagination;
  podcastAllDocs: Document[] = [];
  podcastShownDocs: Document[] = [];

  webinarPagination?: Pagination;
  webinarAllDocs: Document[] = [];
  webinarShownDocs: Document[] = [];

  pilloleVideoSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Video.PILLOLE_VIDEO],
    withText: true,
  });

  approfondimentiSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Video.APPROFONDIMENTI],
    withText: true,
  });

  podcastSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Video.PODCAST],
    withText: true,
  });

  webinarSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Video.WEBINAR],
    withText: true,
  });

  async beforeMount() {
    this.testata = JnService.getTestata(Sections.VIDEO);

    this.pilloleVideoAllDocs = await EsService.getDocs(this.pilloleVideoSearchData);
    this.approfondimentiAllDocs = await EsService.getDocs(this.approfondimentiSearchData);
    this.podcastAllDocs = await EsService.getDocs(this.podcastSearchData);
    this.webinarAllDocs = await EsService.getDocs(this.webinarSearchData);

    this.pilloleVideoPagination = new Pagination({
      page: 1,
      total: this.pilloleVideoAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showPilloleVideo(0, this.pilloleVideoPagination.perPage);

    this.approfondimentiPagination = new Pagination({
      page: 1,
      total: this.approfondimentiAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showApprofondimenti(0, this.approfondimentiPagination.perPage);

    this.podcastPagination = new Pagination({
      page: 1,
      total: this.podcastAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showPodcast(0, this.podcastPagination.perPage);

    this.webinarPagination = new Pagination({
      page: 1,
      total: this.webinarAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showWebinar(0, this.webinarPagination.perPage);
  }

  updated() {
    EventBus.$emit("search-results", []);
  }

  // Pillole Video
  nextPilloleVideo() {
    if (this.pilloleVideoPagination) {
      const from =
        (this.pilloleVideoPagination.page - 1) * this.pilloleVideoPagination.perPage;
      const size = this.pilloleVideoPagination.perPage;
      this.showPilloleVideo(from, size);
    }
  }
  showPilloleVideo(from: number, size: number) {
    this.pilloleVideoShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.pilloleVideoAllDocs[i]) {
        this.pilloleVideoShownDocs.push(this.pilloleVideoAllDocs[i]);
      }
    }
  }

  // Approfondimenti
  nextApprofondimenti() {
    if (this.approfondimentiPagination) {
      const from =
        (this.approfondimentiPagination.page - 1) * this.approfondimentiPagination.perPage;
      const size = this.approfondimentiPagination.perPage;
      this.showApprofondimenti(from, size);
    }
  }
  showApprofondimenti(from: number, size: number) {
    this.approfondimentiShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.approfondimentiAllDocs[i]) {
        this.approfondimentiShownDocs.push(this.approfondimentiAllDocs[i]);
      }
    }
  }

  // Podcast
  nextPodcast() {
    if (this.podcastPagination) {
      const from =
        (this.podcastPagination.page - 1) * this.podcastPagination.perPage;
      const size = this.podcastPagination.perPage;
      this.showPodcast(from, size);
    }
  }
  showPodcast(from: number, size: number) {
    this.podcastShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.podcastAllDocs[i]) {
        this.podcastShownDocs.push(this.podcastAllDocs[i]);
      }
    }
  }

  // Webinar
  nextWebinar() {
    if (this.webinarPagination) {
      const from =
        (this.webinarPagination.page - 1) * this.webinarPagination.perPage;
      const size = this.webinarPagination.perPage;
      this.showWebinar(from, size);
    }
  }
  showWebinar(from: number, size: number) {
    this.webinarShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.webinarAllDocs[i]) {
        this.webinarShownDocs.push(this.webinarAllDocs[i]);
      }
    }
  }
}
